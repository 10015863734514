<template>
  <div>
    <sales-made-annulled-component />
  </div>
</template>

<script>
import SalesMadeAnnulledComponent from "@/views/crm/views/sales-made/components/annulled/SalesMadeAnnulledComponent.vue";
export default {
  name: 'AnnuledComponent',
  components: {SalesMadeAnnulledComponent},
}
</script>

<style scoped>

</style>
