<template>
  <div>
    <filter-slot
      annulled
      :fields="fields"
      :filter="filter"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter-principal="filterPrincipal"
      @reload="$refs['annull-table'].refresh()"
    >
      <b-table
        id="annull-table"
        slot="table"
        ref="annull-table"
        :has-provider="true"
        sticky-header="70vh"
        small
        no-provider-filtering
        class="font-small-3 text-center"
        :busy.sync="isBusy"
        :items="getData"
        :fields="filteredFields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
        :filter="filter"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <div class="text-left">
            <router-link
              :class="textLink"
              :to="{
                name: 'administration-sales-made-report',
                params: {
                  id: data.item.lead_id,
                },
              }"
              target="_blank"
              >{{ data.item.client }}</router-link
            >
          </div>
        </template>

        <!-- Column TY -->
        <template v-slot:cell(type)="data">
          <span v-if="data.item.type == 1" class="text-primary cursor-pointer"
            ><u>AD</u></span
          >
          <span
            @click="openModalProgram(data.item)"
            v-else
            class="text-primary cursor-pointer"
          >
            CH
          </span>
        </template>

        <!-- Column OR -->
        <template v-slot:cell(first_program)="data">
          <span :title="data.value"> {{ getOr(data.value) }} </span>
        </template>

        <!-- Column DE -->
        <template v-slot:cell(de)="data">
          <span :title="data.item.program">
            {{ getOr(data.item.program) }}
          </span>
        </template>

        <!-- Column PROGRAM -->
        <template v-slot:cell(program)="data">
          <b-button
            :style="`color: white !important; border-color: ${data.item.program_color} !important; background-color: ${data.item.program_color} !important;`"
            size="sm"
            class="font-weight-bolder"
          >
            <span v-b-tooltip.bottom="data.item.program">
              {{ data.item.program_initials }}
            </span>
            <feather-icon
              v-if="data.item.haveRates !== 1"
              icon="AlertTriangleIcon"
              style="margin-left: 5px"
            />
            <feather-icon
              v-else
              icon="CheckCircleIcon"
              style="margin-left: 5px"
            />
          </b-button>
        </template>

        <!-- Column: Captured By -->
        <template v-slot:cell(captured)="data">
          <b-row
            class="text-left"
            v-if="
              moduleId != 4 || (moduleId == 4 && data.item.commission == null)
            "
          >
            <b-col>{{ data.item.captured }}</b-col>
          </b-row>
          <b-row v-if="data.item.commission && moduleId != 4" class="text-left">
            <b-col>
              <p class="text-primary font-weight-bold">
                $ {{ JSON.parse(data.item.commission)[0].commission }}
              </p>
            </b-col>
          </b-row>
          <b-row v-if="moduleId == 4" class="m-1">
            <template v-for="(item, index) in JSON.parse(data.item.commission)">
              <b-col v-if="item.type == 'capture'" :key="index">
                <div v-if="item.change != 1" class="text-left">
                  <span>
                    {{ data.item.captured }}
                  </span>
                </div>
                <div v-else><span> - </span></div>
                <div class="text-left">
                  <span class="text-primary">
                    {{ item.change != 1 ? "$ " + item.commission : "" }}
                  </span>
                </div>
              </b-col>
            </template>
          </b-row>
        </template>

        <!-- Column: Seller -->
        <template v-slot:cell(seller)="data">
          <b-row class="text-left">
            <b-col>{{ data.item.seller }}</b-col>
          </b-row>
          <b-row v-if="data.item.commission" class="text-left">
            <b-col>
              <p class="m-0 text-primary font-weight-bold">
                $ {{ JSON.parse(data.item.commission)[1].commission }}
              </p>
            </b-col>
          </b-row>
        </template>

        <!-- Column: Fee $ -->
        <template v-slot:cell(fee)="data">
          <div>
            <span class="text-primary"> $ {{ data.item.fee }} </span>
          </div>
        </template>

        <!-- Column: IP -->
        <template v-slot:cell(initial_amount)="data">
          <div>
            <b-icon
              v-if="
                data.item.initial_payment_status === 1 ||
                data.item.initial_payment_status === 3 ||
                moduleId != 4
              "
              icon="wallet2"
              variant="muted"
            />

            <img
              v-if="moduleId == 4 && data.item.initial_payment_status === 1"
              :src="baseUrl + '/images/icons/CRM--initialpayment-OFF.ico'"
              class="cursor-pointer"
              @click="openModalInitialPayment(data.item)"
            />
            <img
              v-if="moduleId == 4 && data.item.initial_payment_status === 3"
              :src="baseUrl + '/images/icons/unverified-payment.ico'"
              class="cursor-pointer"
              @click="openModalInitialPayment(data.item)"
            />

            <span
              v-if="moduleId == 4 && data.item.initial_payment_status === 2"
              class="cursor-pointer text-success"
              @click="openModalInitialPayment(data.item)"
            >
              {{ "$ " + data.item.initial_amount }}
            </span>
          </div>
        </template>

        <!-- Column: CF -->
        <template v-slot:cell(contract_fee_status)="data">
          <b-icon
            v-if="data.item.contract_fee_status === 0 && moduleId != 4"
            icon="file-text"
            variant="muted"
          />

          <img
            v-if="
              (data.item.contract_fee_status == 0 ||
                (data.item.contract_fee_status == 1 &&
                  data.item.initial_payment_status == 3)) &&
              moduleId == 4
            "
            :src="baseUrl + '/images/icons/CRM--contractfee-OFF.ico'"
            title="Contract Fee"
            class="cursor-pointer"
            style="width: 15px"
            @click="openModalContractFee(data.item)"
          />

          <img
            v-if="
              data.item.contract_fee_status == 1 &&
              data.item.initial_payment_status == 2 &&
              moduleId == 4
            "
            :src="baseUrl + '/images/icons/CRM--contractfee-ON.ico'"
            title="Contract Fee"
            class="cursor-pointer"
            style="width: 15px"
            @click="openModalContractFee(data.item)"
          />

          <img
            v-if="data.item.contract_fee_status == 2 && moduleId == 4"
            :src="baseUrl + '/images/icons/CRM--contractfee-red.ico'"
            title="Contract Fee"
            class="cursor-pointer"
            style="width: 15px"
            @click="openModalContractFee(data.item)"
          />
        </template>

        <!-- Column: NT -->
        <template v-slot:cell(notes_status)="data">
          <b-icon
            v-if="data.item.notes_status == 0 && moduleId != 4"
            icon="chat-square-text-fill"
            variant="muted"
          />

          <img
            v-if="data.item.notes_status == 0 && moduleId == 4"
            :src="baseUrl + '/images/icons/CRM--notes-OFF.ico'"
            title="Notes"
            class="cursor-pointer"
            style="width: 15px"
            @click="modalNotas(data.item)"
          />
          <img
            v-if="data.item.notes_status != 0 && moduleId == 4"
            :src="baseUrl + '/images/icons/CRM--notes-ON.ico'"
            title="Notes"
            class="cursor-pointer"
            style="width: 15px"
            @click="modalNotas(data.item)"
          />
        </template>

        <!-- Column: TK -->
        <template v-slot:cell(trackings)="data">
          <b-icon
            v-if="moduleId != 4"
            class="cursor-pointer"
            icon="list-check"
            variant="muted"
          />
          <b-icon
            v-else
            class="cursor-pointer font-medium-2"
            icon="list-check"
            :variant="data.item.trackings ? 'success' : 'muted'"
            @click="openTrackingModal(data.item)"
          />
        </template>

        <!-- Column: FI -->
        <template v-slot:cell(files)="data">
          <b-icon
            class="cursor-pointer"
            icon="folder-fill"
            variant="warning"
            @click="openFilesModal(data.item)"
          />
        </template>

        <!-- Column: ST -->
        <template v-slot:cell(status)="data">
          <p
            v-if="data.item.status >= 0 && data.item.status <= 4"
            class="m-0 font-weight-bold font-small-3"
            :class="'color: text-' + status[data.item.status].variant"
          >
            {{ status[data.item.status].label }}
          </p>
        </template>

        <!-- Column: Created -->
        <template v-slot:cell(creates)="data">
          <span>{{ data.item.creates | myGlobal }}</span>
        </template>

        <!-- Column: Approved -->
        <template v-slot:cell(approved)="data">
          <span
            class="font-weight-bold"
            :class="data.item.approved ? 'text-info' : ''"
            >{{ data.item.approved | myGlobal }}</span
          >
        </template>

        <!-- Column: Annulled By -->
        <template v-slot:cell(annulled_by)="data">
          <div>
            <p>{{ data.item.annulled_by }}</p>
            <p>{{ data.item.annulled_at | myGlobal }}</p>
          </div>
        </template>
      </b-table>
    </filter-slot>
    <files-modal
      v-if="modal.files"
      :modal="modal"
      :files="modalIP"
      :mode="moduleId"
      @close="modal.files = false"
    />

    <!-- Modal Initial Payment -->
    <modal-initial-payment
      v-if="modalInitialPayment"
      :modal="modal"
      :initial_payment="modalIP"
      @close="modalInitialPayment = false"
    />

    <!-- Modal Contract Fee -->
    <modal-contract-fee
      v-if="modalContractFee"
      :modal="modal"
      :contractFee="modalIP"
      @close="modalContractFee = false"
    />

    <!-- Modal Notes -->
    <component
      :is="modalIP.programSelected"
      v-if="modalNotes"
      :note-info="modalIP"
      @hide="modalNotes = false"
    />

    <component
      :is="modalIP.programsTY"
      v-if="modal.programs"
      :modal-services="modal.programs"
      :sales-client="modalIP"
      :type-modal="modalIP.typeModal"
      @closeModal="hideModalProgram"
    />

    <!-- Modal Tracking -->
    <modal-tracking :modal="modal" :tracking="modalIP" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import CrmService from "@/views/crm/services/crm.service";

/* MODALS */
import FilesModal from "@/views/crm/views/sales-made/components/modals/FilesModal.vue";
import ModalInitialPayment from "@/views/crm/views/sales-made/components/modals/InitialPaymentModal.vue";
import ModalContractFee from "@/views/crm/views/sales-made/components/modals/ContractFeeModal.vue";

import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalNotesCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import ModalTracking from "@/views/crm/views/sales-made/components/modals/TrackingModal.vue";

/* DATA */
import dataFields from "./fields.data";
import dataFilters from "./filters.data";
/* Modals Import can be deleted */

export default {
  name: "SalesMadeAnnulledComponent", // Change name component
  components: {
    FilesModal,
    FilterSlot,
    ModalInitialPayment,
    ModalContractFee,
    ModalNotesBoost,
    ModalNotesParagon,
    ModalNotesFirst,
    ModalNotesTax,
    ModalNotesCredit,
    ModalNotesAll,
    ModalNotesSpecialist,
    ModalTracking,
    BusinessModal,
    BoostCreditModal,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      items: {},
      isBusy: false,
      fields: dataFields,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,
      modal: {
        tracking: false,
        initial_payment: false,
        captuerd_by_tracking: false,
        files: false,
        programs: false,
        revission: false,
        url: false,
        contract_fee: false,
        notes: false,
        approveSupervisorModal: false,
        modalSms: false,
        modalValidateDateAndHour: false,
      },
      modalData: {
        files: {
          id: null,
          program: "",
          client: "",
          sale_id: null,
        },
      },
      modalControllers: {
        files: 0,
      },
      modalIP: {
        programsTY: "boost-credit-modal",
      },
      modalInitialPayment: false,
      modalContractFee: false,
      modalNotes: false,
      modalTracking: false,
    };
  },
  computed: {
    ...mapState({
      sellers: (state) => state["crm-store"].sellersCrm,
      captured: (state) => state["crm-store"].capturedCrm,
      // TODO HACERLO GLOBAL
      programs: (state) => state["crm-store"].programs,
      stip: (state) => state["crm-store"].statusip,
      status: (state) => state["crm-store"].statusAnnuled,
      statusFilter: (state) => state["crm-store"].statusFilter,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_IS_SUPERVISOR: "auth/isSupervisor",
      G_IS_SELLER: "auth/isSeller",
      G_IS_CEO: "auth/isCeo",
      G_MODULE_ID: "auth/moduleId",
      G_USER_ROLE: "auth/userRole",
      G_IS_ADVISOR: "auth/isAdvisor",
      G_USER_SESSION: "auth/userSession",
      G_ROLE_ID: "auth/roleId",
    }),
    filteredFields() {
      this.fields.forEach((field) => {
        field.visible = true;
      });

      this.fields[1].visible = false;
      this.fields[2].visible = false;
      this.fields[3].visible = false;
      if (this.moduleId == 4) {
        this.fields[1].visible = true;
        this.fields[2].visible = true;
        this.fields[3].visible = true;
        this.fields[4].visible = false;
        this.fields[13].visible = false;
        this.fields[16].visible = false;
      }
      return this.fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    try {
      await Promise.all([
        this.$store.dispatch("crm-store/getSellers"),
        this.$store.dispatch("crm-store/getCaptured"),
        this.$store.dispatch("crm-store/getPrograms"),
        this.$store.dispatch("crm-store/getStates"),
      ]);
      this.filter[2].options = this.captured;
      this.filter[3].options = this.sellers;
      this.filter[4].options = this.statusFilter;
      this.filter[5].options = this.programs;
      this.filter[6].options = this.stip;
    } catch (error) {
      console.error(error);
    }

    this.addPaddingTd();
  },
  methods: {
    openFilesModal(item) {
      this.modalIP.id = item.lead_id;
      this.modalIP.program = item.program;
      this.modalIP.client = item.client;
      this.modalIP.sale_id = item.id;
      this.modalIP.programId = item.program_id;
      this.modalIP.event_date = item.event_date;
      this.modalIP.client_account_id = item.client_account_id;
      const isCeoOrSupervisor =
        this.currentUser.role_id == "1" || this.currentUser.role_id == "2";
      const saleStatus = item.status == "4" || item.status == "2";
      if (
        (this.currentUser.user_id == item.user_id || isCeoOrSupervisor) &&
        saleStatus == false
      ) {
        this.modal.editmodal = true;
      } else {
        this.modal.editmodal = true;
      }
      this.modal.files = true;
    },

    async getData(ctx) {
      if (this.moduleId == 4) {
        return await this.myProviderRecovered(ctx);
      } else {
        return await this.myProvider(ctx);
      }
    },
    async myProvider(ctx) {
      try {
        const sortBy = 30;
        const sortDirection = "desc";
        const params = {
          captured: this.filter[2].model,
          from: this.filter[0].model,
          order: sortDirection,
          orderby: sortBy,
          program: this.filter[5].model,
          rolsession: this.currentUser.role_id,
          salemade: 0,
          seller: this.filter[3].model,
          status: this.filter[4].model,
          statusip: this.filter[6].model,
          text: this.filterPrincipal.model,
          to: this.filter[1].model,
          per_page: this.paginate.perPage,
        };
        const data = await CrmService.getSaleAnnul(params, ctx.currentPage);
        this.startPage = data.from;
        this.toPage = data.to;
        if (this.totalRows !== data.total) this.totalRows = data.total;
        this.items = data.data;
        return this.items;
      } catch (e) {
        this.showToast("danger", "top-right", "Error", "XIcon", e);
        return [];
      }
    },
    async myProviderRecovered(ctx) {
      try {
        const sortBy = 30;
        const sortDirection = "desc";
        const params = {
          captured: this.filter[2].model,
          from: this.filter[0].model,
          order: sortDirection,
          orderby: 11,
          modul: this.$route.meta.program,
          program: this.filter[5].model,
          rolsession: this.currentUser.role_id,
          salemade: this.moduleId != 4 ? 0 : 1,
          seller: this.isSeller(),
          status: this.filter[4].model,
          statusip: this.filter[6].model,
          text: this.filterPrincipal.model,
          to: this.filter[1].model,
          perPage: this.paginate.perPage,
          recovered: this.moduleId == 4 ? 1 : null,
          type: 0,
          from_connection: 1,
        };
        const { data } = await CrmService.searchSaleProgram(
          params,
          ctx.currentPage
        );
        this.startPage = data.from;
        this.toPage = data.to;
        if (this.totalRows !== data.total) this.totalRows = data.total;
        this.items = data.data;
        return this.items;
      } catch (e) {
        this.showToast("danger", "top-right", "Error", "XIcon", e);
        return [];
      }
    },
    isSeller() {
      if (
        this.currentUser.role_id === 3 &&
        (this.moduleId === 7 || this.moduleId === 14)
      ) {
        if (this.filter[4].model === "") {
          return this.currentUser.user_id;
        }
        return this.filter[4].model;
      } else return this.filter[4].model;
    },

    getOr(val) {
      let or = "";
      switch (val) {
        case "Business":
          or = "BU";
          break;
        case "Boost Credit":
          or = "BC";
          break;
        case "Credit Experts":
          or = "CE";
          break;
        case "Debt Solution":
          or = "DS";
          break;
        case "Tax Research":
          or = "TR";
          break;
        case "Claim Department":
          or = "GS";
          break;
        case "Specialist":
          or = "SP";
          break;
        case "Bookeeping":
          or = "BK";
          break;
        case "Paragon":
          or = "PR";
          break;
        default:
          break;
      }

      return or;
    },
    async openModalInitialPayment(item) {
      this.modalIP = {};
      try {
        this.addPreloader();
        this.modalIP = item;
        this.modalIP.programid = item.program_id;
        this.modalIP.sessionId = this.currentUser.user_id;
        this.modalIP.cfeestatus = item.contract_fee_status;
        this.modalIP.id_transaction = item.transaction_id;

        if (
          this.currentUser.role_id == 1 ||
          this.currentUser.role_id == 2 ||
          this.currentUser.role_id == 3
        ) {
          this.modalIP.editmodal = true;
        } else {
          this.modalIP.editmodal = false;
        }
        this.modalIP.statusSale = item.status;
        this.modalIP.nameProgram = item.program;
        this.modalIP.nameClient = item.client;
        this.modalIP.valorInitalPayment = item.initial_payment_status;
        this.modalIP.feeprops = item.fee;

        const cards = await amgApi.post("/clients/search-cards-clients", {
          id: item.lead_id,
        });
        if (cards.status === 200) {
          this.modalIP.allcards = cards.data;
        }
        const response = await amgApi.post(
          "/sales-made/get-payments-sales-made",
          { id: item.id }
        );
        if (response.status === 200) {
          [this.modalIP.payments] = response.data;
        }
        this.modalIP.modul = 4;
        this.modalIP.role_id = this.currentUser.role_id;
        this.modalInitialPayment = true;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    openModalContractFee(item) {
      this.modalIP = {};
      if (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 3
      ) {
        this.modalIP.editmodal = true;
      } else {
        this.modalIP.editmodal = false;
      }

      this.modalIP.statusSale = item.status;
      this.modalIP.clientName = item.client;
      this.modalIP.programName = item.program;
      this.modalIP.id = item.lead_id;
      this.modalIP.saleId = item.id;
      this.modalIP.initialPaymentStatus = item.initial_payment_status;
      this.modalIP.initialAmount = item.initial_amount;

      this.modalContractFee = true;
    },

    modalNotas(item) {
      this.modalIP = {};
      if (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 3
      ) {
        this.modalIP.editmodal = true;
      } else {
        this.modalIP.editmodal = false;
      }

      this.addPreloader();

      this.modalIP.capturedName = item.captured;
      this.modalIP.sellerName = item.seller;
      this.modalIP.trackings = item.trackings;
      this.modalIP.nameProgram = item.program;
      this.modalIP.nameClient = item.client;
      this.modalIP.statusSale = item.status;
      this.modalIP.sourcesName = item.sourcesname;
      this.modalIP.pack = item.pack;
      this.modalIP.originCountry = item.origin_country;
      this.modalIP.idLead = item.lead_id;
      this.modalIP.created = item.creates;
      this.modalIP.saleId = item.id;
      this.modalIP.module = this.moduleId;
      this.modalIP.rolId = this.currentUser.role_id;
      this.modalIP.notes_status = item.notes_status;
      this.modalIP.notes_status_new = item.notes_status_new;

      this.openModalNotes(item.creates, item.program_id);
    },

    openModalNotes(created, program) {
      switch (true) {
        case created >= "2020-05-28" && program == 1:
          this.modalIP.programSelected = "ModalNotesFirst"; // ready
          break;
        case created >= "2020-10-29" && program == 2:
          this.modalIP.programSelected = "ModalNotesBoost"; // ready
          break;
        case created >= "2021-03-04" && program == 3:
          this.modalIP.programSelected = "ModalNotesCredit"; // ready
          break;
        case created >= "2020-09-24" && program == 5:
          this.modalIP.programSelected = "ModalNotesTax"; // ready
          break;
        case created >= "2020-10-23" && program == 7:
          this.modalIP.programSelected = "ModalNotesSpecialist"; // ready
          break;
        case program == 9:
          this.modalIP.programSelected = "ModalNotesParagon"; // ready
          break;
        default:
          // next
          this.modalIP.programSelected = "ModalNotesAll";
          break;
      }
      this.modalNotes = true;
    },

    openTrackingModal(item) {
      this.modalIP = {};
      this.modalIP.program = item.program;
      this.modalIP.client = item.client;
      this.modalIP.tabla = [];
      if (item.trackings) {
        this.modalIP.tabla = JSON.parse(item.trackings);
      }
      this.modalTracking = true;
      this.modal.tracking = true;
    },

    openModalProgram(item) {
      this.modalIP = item;
      this.modalIP.typeModal = 5;
      this.modalIP.programsTY = "boost-credit-modal"; // ready
      this.modal.programs = true;
    },

    async hideModalProgram(refresh) {
      if (refresh) {
        this.$refs["annull-table"].refresh();
      }
      this.modal.programs = false;
      this.removePreloader();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
</style>
